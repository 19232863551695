import { handleResponse, SERVER_HOST } from "@api";
import { TBrand, TProduct } from "@components/types";
import axios from "axios";

export const getProductBrands = async (name: string = null, category: string = null, pageNumber: number = 0, pageSize: number = 10) => {
    let axiosMethod = axios.get;
    const res = await axiosMethod(`${SERVER_HOST}/api/v1/brands/?${name == null ? "" : `name=${name}`}${category == null ? "" : `&category=${category}`}&skip=${pageNumber * pageSize}&limit=${pageSize}`,)
    return handleResponse(res);
}

export const getBrand = async (id: number) => {
    let axiosMethod = axios.get;
    const res = await axiosMethod(`${SERVER_HOST}/api/v1/brands/${id}`,)
    return handleResponse(res);
}

export const getBestSeller = async (id: number) => {
    const res = await fetch(`${SERVER_HOST}/api/v1/products/popular/?brand_id=${id}`)
    const products: TProduct[] = await res.json()
    return products;
}

export async function getAllBrands(
  categoryId: number = null,
  orderingOn: string = null, 
  skip: number = 0, 
  limit: number = 10,
) {
  const response = await axios.get(`${SERVER_HOST}/api/v1/brands/?${categoryId ? `category_id=${categoryId}&` : ''}${orderingOn ? `ordering_on=${orderingOn}&` : ''}skip=${skip}&limit=${limit}`);
  return handleResponse(response);
}

export async function getRootCategories() {
    const response = await axios.get(`${SERVER_HOST}/api/v1/productcategories/?root=true&ascending=false&skip=0&limit=50`)
    return handleResponse(response);
}

export const getBrandCategories = async (brand_id: number = null, pageNumber: number = 0, pageSize: number = 20) => {
    const res = await axios.get(`${SERVER_HOST}/api/v1/productcategories/?${brand_id ? `brand_id=${brand_id}&` : ''}skip=${pageNumber * pageSize}&limit=${pageSize}`)
    const productCategoriesList = handleResponse(res);
    return productCategoriesList;
}

export const getHomegrownBrands = async (onlyPublished: boolean = true, pageNumber: number = 0, pageSize: number = 20) => {
    let axiosMethod = axios.get;
    const res = await axiosMethod(`${SERVER_HOST}/api/v1/brands/newlaunch?only_published=true&lat=0&lon=0&skip=${pageNumber}&limit=${pageSize}`,
        { headers: { "Content-Type": "application/json", "Authorization": `Bearer ${localStorage.getItem("token")}` } })
    return handleResponse(res);
}

export const getNewHomeGrownBrands = async (skip: number = 0, limit: number = 20) => {
    let axiosMethod = axios.get;
    const res = await axiosMethod(`${SERVER_HOST}/api/v1/brands/?homegrown=true&skip=${skip}&limit=${limit}`, {
        headers: { 'Content-Type': 'application/json' }
    });
    return handleResponse(res);
}