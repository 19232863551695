import { SERVER_HOST, handleResponse } from "@api";
import axios from "axios";

export const getNewLaunchProducts = async (onlyPublished: boolean = true, pageNumber: number = 0, pageSize: number = 10) => {
    let axiosMethod = axios.get;
    const res = await axiosMethod(`${SERVER_HOST}/api/v1/products/newlaunch?only_published=true&lat=0&lon=0&skip=${pageNumber}&limit=${pageSize}`,
        { headers: { "Content-Type": "application/json"}})
    return handleResponse(res);
}

export const getNewLaunchBrands = async (onlyPublished: boolean = true, pageNumber: number = 0, pageSize: number = 10) => {
    let axiosMethod = axios.get;
    const res = await axiosMethod(`${SERVER_HOST}/api/v1/brands/newlaunch?only_published=true&lat=0&lon=0&skip=${pageNumber}&limit=${pageSize}`,
        { headers: { "Content-Type": "application/json" } })
    return handleResponse(res);
}